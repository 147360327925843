// Third-party libraries
import React from "react";
import Cookies from "universal-cookie";
import axios from "axios";

// Application-specific modules
import appConfig from "../appConfig";
import { fetchHeadersWithAuth } from "../../api/routes/api.route";
import { BillingFormActive, PaymentActive, PaymentMethod } from "../types";
import Helper from "../helper/helper";


class PaymentService extends React.Component {
  static cookies = new Cookies();

  static billingForm = null;
  static billingOptionsDefault = [
    { id: BillingFormActive.NEW, value: BillingFormActive.NEW, label: "Novos dados de faturação", removable: false },
    // { id: BillingFormActive.CLIENT, value: BillingFormActive.CLIENT, label: "Dados de cliente", removable: false },
  ];

  static billingOptions = [...PaymentService.billingOptionsDefault];

  static billingData = [];

  static billingDataMapper = (d) => {
    return {
      id: d?.id || BillingFormActive.CLIENT,
      name: d?.name,
      nif: d?.vat_number || d?.vat,
      address: d?.address,
      zip: d?.postal_code,
      city: d?.city,
      selectedCountry: d?.country || {id: d?.country_id }//d?.country_id}
    };
  };

  static billingDataDto = (d) => {
    let dto = {
      name: d?.name,
      vat_number: d?.nif,
      address: d?.address,
      postal_code: d?.zip,
      city: d?.city,
      country_id: d?.selectedCountry?.id
    };

    if (d?.id !== BillingFormActive.CLIENT && d?.id !== BillingFormActive.NEW) {
      dto.id = d?.id;
    }

    return dto;
  };

  static billingOptionMapper = (b) => {
    const id = !b?.id ? BillingFormActive.CLIENT : b?.id;
    const label = b.id && b?.nif ? `${b?.name} (${b?.nif})` : `Criar dados de cliente`;
    return { id, value: id, label, removable: b?.id && b.id !== BillingFormActive.CLIENT, isNewClient: b?.id === BillingFormActive.CLIENT && !b?.nif };
  };

  static detailsMapper = (d) => {
    return !d ? null : ({
      reference: d.reference,
      entity: d.entity,
      expirationDate: d.expiration_date,
      waitingTime: d.waiting_time,
      phone: d.phone,
      url: d.url,
      type: d.type,
      id: d.id,
    });
  }

  static convertTypeIntoMethod = (type) => {
    return (type === PaymentActive.CC
      ? PaymentMethod.CC
      : type === PaymentActive.MB
        ? PaymentMethod.MB
        : PaymentMethod.MBWAY);
  };

  /**
   * Makes a call to the payment API.
   * @param {string} id - The payment identifier.
   * @param {PaymentMethod} method - The payment method (e.g., "MB", "MBWAY", "CC").
   * @param {string} phone - The phone number for MBWAY payment, if applicable.
   */
  static callPaymentApi = async ({id, method, total, phone}) => {
    try {
      const billingId = this.billingForm?.id === BillingFormActive.CLIENT ? null : this.billingForm?.id;
      const body = {
        id, method, total, phone,
        billing_id: billingId || null
      };
      const response = await axios.post(
        `${appConfig.BASE_URL}/payment`, body,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
      return {
        success: true, details: this.detailsMapper(response?.data)
      };
    } catch (error) {
      //console.error("Error fetching Payment API:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Makes a call to get the payment state.
   * @param {string} id - The service identifier.
   */
  static getPaymentStatus = async (id) => {
    try {
      // const params = { id }
      const response = await axios.get(
        `${appConfig.BASE_URL}/payment/status/${id}`,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
      return { success: true, data: response?.data?.status };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  /**
   * Makes a call to get the payment state.
   * @param {string} id - The payment fo identifier.
   * @param {"SUCCESS" | "ERROR" | "CANCEL"} status - The service identifier.
   * @param {string} sk
   */
  static updateCCState = async ({id, status, sk}) => {
    try {
      const body = {
        payment_id: id,
        status: status?.toUpperCase(),
        sk
      };
      const response = await axios.post(
        `${appConfig.BASE_URL}/payment/cc/update`, body,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
      return { success: true, data: response?.data };
    } catch (error) {
      console.error("Error fetching updateCCState:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Makes a call to get billing data list.
   */
  static useMock = false;
  static getBillingData = async () => {
    try {
      const response = await axios.get(
        `${appConfig.BASE_URL}/client/billing`,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
 
      if (response?.data) {
        let data = null;
        if (this.useMock) {
          data = billingDataMock.map(d => this.billingDataMapper(d));
        } else {
          data = response?.data?.data?.map(d => this.billingDataMapper(d));
        }
        
        this.billingData = [...data];
        this.billingOptions = this.billingOptionsDefault?.concat(data?.map(d => this.billingOptionMapper(d)));
      }
      return { success: true, data: this.billingOptions };
    } catch (error) {
      console.error("Error fetching getBillingData:", error);
      return { success: false, error: error.message };

      // const mockData = billingDataMock?.map(d => PaymentService.customerBillingMapper(d));

      // if (mockData) {
      //   this.billingData = [...mockData];
      //   this.billingOptions = this.billingOptions.concat(mockData?.map(d => this.billingOptionMapper(d)));
      // }
      // return { success: true, data: PaymentService.billingOptions };
    }
  };

/**
 * Creates a new billing entry.
 * @param {Object} billingData - The billing data to be sent in the POST request.
 * @returns {Promise<Object>} - The response containing success status and created data or error message.
 */
  static createBillingData = async (billingData) => {
    try {
      const response = await axios.post(
        `${appConfig.BASE_URL}/client/billing`,
        this.billingDataDto(billingData),
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );

      return { success: true, data: response }; 
    } catch (error) {
      console.error("Error creating billing data:", error);
      return { success: false, error: error.message };
    }
  };
  
  /**
   * Updates an existing billing entry.
   * @param {Object} billingData - The updated billing data to be sent in the POST request.
   * @returns {Promise<Object>} - The response containing success status and updated data or error message.
   */
  static updateBillingData = async (billingData) => {
    try {
      const response = await axios.post(
        `${appConfig.BASE_URL}/client/billing/update`,
        this.billingDataDto(billingData),
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
  
      return { success: true, data: response };

    } catch (error) {
      console.error("Error updating billing data:", error);
      return { success: false, error: error.message };
    }
  };

/**
 * Deletes an existing billing entry.
 * @param {number|string} billingId - The ID of the billing entry to be deleted.
 * @returns {Promise<Object>} - The response containing success status or error message.
 */
  static deleteBillingData = async (billingId) => {
    try {
      const response = await axios.delete(
        `${appConfig.BASE_URL}/client/billing/${billingId}`,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );

      return { success: true, data: response};

    } catch (error) {
      console.error("Error deleting billing data:", error);
      return { success: false, error: error.message };
    }
  };

  static billingFormValidation = () => {
    const base = { selectedCountry: null, name: "", nif: "", address: "", zip: "", city: ""}
    const emptyParams = Helper.checkEmptyParams(base, PaymentService.billingForm);
    return emptyParams?.length === 0;
  };

  static billingFormNifValidation = () => {
    // return true;
    return PaymentService.billingForm?.selectedCountry?.id === 175
      && !Helper.checkVatNumber(PaymentService.billingForm?.nif) ? false : true;
  }

}

export default PaymentService;
const billingDataMock = [
    {
      name: "Ricardo Samões",
      country:  
        { id: 175, country: "Portugal" },
    },
    {
        id: 1,
        name: "João Miguel",
        vat_number: "212741276",
        address: "Rua das Flores, 123",
        postal_code: "4000-123",
        city: "Porto",
        country:  
          { id: 175, country: "Portugal" },
      },
  ];

// const billingDataMock = [
//   {
//     id: 1,
//     name: "João Miguel",
//     vat_number: "257000000",
//     address: "Rua das Flores, 123",
//     postal_code: "4000-123",
//     city: "Porto",
//     country:  
//       { id: 175, country: "Portugal" },
//   },
//   {
//     id: 2,
//     name: "Maria Oliveira",
//     vat_number: "278500001",
//     address: "Avenida da Liberdade, 45",
//     postal_code: "1250-141",
//     city: "Lisboa",
//     country:  
//       { id: 175, country: "Portugal" },
//   },
//   {
//     id: 3,
//     name: "Carlos Silva",
//     vat_number: "302800002",
//     address: "Travessa do Sol, 12",
//     postal_code: "3030-321",
//     city: "Coimbra",
//     country:  
//       { id: 175, country: "Portugal" },
//   },
//   {
//     id: 4,
//     name: "Ana Ferreira",
//     vat_number: "314900003",
//     address: "Rua do Mar, 67",
//     postal_code: "8500-607",
//     city: "Lagos",
//     country:  
//       { id: 175, country: "Portugal" },
//   },
//   {
//     id: 5,
//     name: "Pedro Santos",
//     vat_number: "326700004",
//     address: "Rua do Norte, 90",
//     postal_code: "4900-019",
//     city: "Viana do Castelo",
//     country:  
//       { id: 175, country: "Portugal" },
//   },
//   {
//     id: 6,
//     name: "Sofia Almeida",
//     vat_number: "347500005",
//     address: "Praça do Comércio, 1",
//     postal_code: "1100-148",
//     city: "Lisboa",
//     country:  
//       { id: 175, country: "Portugal" },
//   },
// ];
