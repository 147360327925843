import React, { useEffect, useState } from "react";
import { PaymentActive, PaymentMethod, PaymentState } from "../../core/types";
import { PaymentLogo } from "./PaymentLogo";
import Labels from "../../variables/labels";
import CountdownTimer from "../../core/helper/CountdownTimer";
import { CardIcon } from "./PaymentMethods";

const PaymentStateDetails = (props) => {
  const [ payment, setPayment ] = useState(props.payment);
  const [ selectedMethod, setSelectedMethod ] = useState(props.selectedMethod);
  const [ isPending, setIsPending ] = useState(props.payment?.state === PaymentState.PENDING);
  const [ isPayed, setIsPayed ] = useState(props.payment?.state === PaymentState.PAYED);
  const [ isValidating, setIsValidating ] = useState(props.payment?.urlParams?.status === "success" && props.payment?.state === PaymentState.PENDING && props.payment?.details?.type === PaymentActive.CC);
  
  useEffect(() => {
    setPayment(props.payment);
    setIsPending(props.payment?.state === PaymentState.PENDING);
    setIsPayed(props.payment?.state === PaymentState.PAYED);
    setIsValidating(props.payment?.urlParams?.status === "success" && props.payment?.state === PaymentState.PENDING && props.payment?.details?.type === PaymentActive.CC)
  }, [props.payment]);

  useEffect(() => {
    setSelectedMethod(props.selectedMethod);
  }, [props.selectedMethod]);

  const renderMBState = () => {
    return payment?.details?.type !== PaymentActive.MB || !isPending? null : (
      <div className="pt-1 inline-flex-center w-100">
        <div className="me-3 payment-info-container">
          <p className='medium-semibold-black ps-0'>
            {Labels.payment.details.entity} <span className="medium-regular-black"> {payment?.details?.entity} </span>
          </p>
          <p className='medium-semibold-black ps-0'>
            {Labels.payment.details.reference} <span className="medium-regular-black"> {payment?.details?.reference}</span>
          </p>
          <p className='medium-semibold-black ps-0 mt-3'>
            Valor a pagar: <span className="medium-regular-black">{payment?.totalDisplay} € </span>
          </p>
        </div>

        <div className="ms-3 flex-center" style={{ flexDirection: "column" }}>
          <div className="flex-center w-100 pb-2">
            <PaymentLogo type={PaymentMethod.MB} imgStyle={{ maxHeight: "calc(100% - 6px)" }} />
          </div>
          <div className="flex-center medium-semibold-black" style={{ fontSize: "16px", flexDirection: "column" }}>
            <div>{Labels.payment.details.expiration}</div>
            <div  className="mt-1" style={{ fontSize: "18px" }}>{payment?.details?.expirationDate}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderMBWayState = () => {
    const renderMbWayPending = () => {
      return !isPending ? null : (
        <div className="flex-center medium-semibold-black text-center" style={{ fontSize: "16px", flexDirection: "column" }}>
          <div> Por favor confirme o seu pagamento na sua App MB WAY
          </div>
          <div className="mt-1">{payment.details?.phone}
          </div>
          {payment?.details?.waitingTime && <div className="mt-2" style={{ fontSize: "22px" }}><CountdownTimer initialTimeInSeconds={payment?.details?.waitingTime} /></div>}
        </div>
      );
    };

    const renderMbWayError = () => {
      return (isPending || payment?.state === PaymentState.PAYED) ? null : (
        <div className="flex-center text-center" style={{ flexDirection: "column" }}>
          <div> Por favor tente novamente ou selecione outro método de pagamento, obrigado.
          </div>
        </div>
      );
    }
    return payment?.details?.type !== PaymentActive.MBWAY ? null : (
      <div className="pt-1 inline-flex-center w-100">
        <div className="flex-center" style={{ flexDirection: "column" }}>
          <div className="flex-center w-100 pb-2">
            <PaymentLogo type={PaymentMethod.MBWAY} />
          </div>
          {renderMbWayError()}
          {renderMbWayPending()}

        </div>
      </div>
    );
  };

  const renderCCState = () => {
    // const renderCardInfo = () => {
    //   return (!payment.urlParams?.brand && !payment.urlParams?.pan) || !isPending || isValidating? null : (
    //     <div className="flex-center medium-regular-black text-center" style={{ fontSize: "16px", flexDirection: "column" }}>
    //       <div > {payment.urlParams?.brand} <br />  {payment.urlParams?.pan} <br />
    //         {isValidating ? null : (
    //           <div className="mt-2">
    //             Se desejar escolher outro cartão, continue o pagamento.
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   );
    // };
    const renderNotPayedMessage = () => {
      return isPayed || selectedMethod !== PaymentMethod.CC || isValidating ? null : (
        <div className="flex-center medium-regular-black text-center" style={{ fontSize: "16px", flexDirection: "column" }}>
          <div className="mt-2">
            O seu pagamento pode estar em validação.
            <br /> Se ainda não efetuou a transação, por favor, continue o processo.
          </div>
        </div>
      );
    };

    const renderIcon = () => {
      return selectedMethod !== PaymentMethod.CC || isValidating ? null : (
        <div className="flex-center w-100 pb-2">
          <CardIcon />
        </div>
      );
    };

    return payment?.details?.type !== PaymentActive.CC ? null : (
      <div className="pt-1 inline-flex-center w-100">
        <div className="flex-center" style={{ flexDirection: "column" }}>
          {renderIcon()}
          {/* {renderCardInfo()} */}
          {renderNotPayedMessage()}
        </div>
      </div>
    );
  };

  // const renderExpirationDate = () => {
  //   const date = payment?.expirationDate?.split(" ")[0];
  //   return payment?.details?.type !== PaymentActive.MB || !date ? null : (
  //     <div className="mt-1">{Labels.payment.details.expiration} {date}</div>
  //   );
  // };

  const renderDescription = () => {
    const desc = isValidating ? Labels.payment.validation : Labels.payment.description(payment?.details?.type, payment?.state)
    return (
      <div className='medium-regular-black'>
        {desc}
        {!isValidating ? null : <div className="mt-1"> {payment.urlParams?.brand} <br />  {payment.urlParams?.pan} </div>}
        {/* {renderExpirationDate()} */}
      </div>
    );
  };

  return !payment?.details?.type ? null : (
    <div className="my-3 px-4 payment-methods w-100 align-left">
    {/* <h3>{Labels.payment.title}</h3> */}
      {renderDescription()}
      {/* grey-box-container  */}
      <div className="w-100 mt-2 px-3 pt-2 pt-lg-3">
        {renderMBState()}
        {renderMBWayState()}
        {renderCCState()}
      </div>
  </div>
  );
};

export default PaymentStateDetails;