// React Imports
import React from 'react';
// Style
import 'components/list/tasks/tasks_row.style.scss';
// Assets
import { SERVICE } from 'assets/images/images.js';

class TasksRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      taskIndex: this.props.taskIndex,
      taskValue: this.props.taskValue,
    }
  }

  detailStatus(completed) {
    const { hasTaskCompleted } = this.props;

    if (!hasTaskCompleted) {
      return;
    }

    if (!completed) {
      return <div className='tasks-row-status ms-3'></div>;
    }
    return <img className='tasks-row-status ms-3' src={SERVICE.checkmark} alt="" />;
  }

  renderDiscount(discount) {
    if (!discount) { return; }
    return (
        <div className="tasks-row-text discount me-2"> -{discount}% </div>
    );
  }

  renderPrice(value) {
    if (!value) {
      return;
    }
    return `${value} €`;
  }

  render() {
    const { taskIndex, taskValue: { designation, price, completed, discount } } = this.state;
    // const styleClassRight = discount && completed ? 'col-5 col-sm-4 col-md-3 col-lg-4 col-xl-3' : 'col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2';
    const styleClassRight = 'col-6 col-sm-4';

    return (
      <li key={taskIndex} className='tasks-row-container py-2'>
        <div className={`tasks-row-text text-align-left flex-start col-6 col-md-8`} >{designation.toLowerCase()}</div>
        <div className={`tasks-row-text d-flex align-items-center justify-content-end ${styleClassRight}`}>
          {this.renderDiscount(discount)}
          {this.renderPrice(price)}
          {this.detailStatus(completed)}
        </div>
      </li>
    );
  }
}

export default TasksRow;
