// =======================
// PAYMENT
// =======================
const BillingFormActive = Object.freeze({
  CLIENT: "CLIENT",
  NEW: "NEW",
  OTHER: "OTHER",
  FINAL_CONSUMER: "FINAL_CONSUMER",
});

const PaymentMethod = Object.freeze({
  MB: "MB",
  MBWAY: "MBWAY",
  CC: "CC",
});

const PaymentActive = Object.freeze({
  MB: "MbPayment",
  MBWAY: "MbWayPayment",
  CC: "CcPayment",
});

const PaymentState = Object.freeze({
  PENDING: "PENDING",
  PAYED: "PAYED",  // Explicitly defining the value for "PAYED"
  EXPIRED: "EXPIRED",
  NOT_PAID: 'NOT_PAID',
  CC_CANCEL: "CC_CANCEL",  // Explicitly defining the value for "CC_CANCEL"
  CC_ERROR: "CC_ERROR",    // Explicitly defining the value for "CC_ERROR"
  MBWAY_REJECTED: "MBWAY_REJECTED",  // Explicitly defining the value for "MBWAY_REJECTED"
  MBWAY_DECLINED: "MBWAY_DECLINED",  // Explicitly defining the value for "MBWAY_DECLINED"
});

const PaymentMethodLabel = Object.freeze({
  MB: "Multibanco",
  MBWAY: "MB WAY",
  CC: "Cartão",
});

const PaymentStateLabel = Object.freeze({
  PENDING: "Pendente",
  PAYED: "Pago",
  EXPIRED: "Expirado",         
  CANCEL: "Cancelado",         
  CC_CANCEL: "Cancelado",  
  CC_ERROR: "Erro",        
  MBWAY_REJECTED: "Rejeitado (MBWay)",  
  MBWAY_DECLINED: "Recusado (MBWay)" 
});

// =======================
// TOAST
// =======================
const ToastType = Object.freeze({
  ACTION: "action",
  MESSAGE: "message",
  INTERNAL: "internal",
  PAYMENT: "payment"
});

const ToastState = Object.freeze({
  EXPANDED: "EXPANDED",
  IGNORE: "IGNORE",
});

// Export groups
export {
  PaymentMethod, PaymentState, PaymentMethodLabel, PaymentStateLabel, PaymentActive,
  BillingFormActive,
  ToastType, ToastState, 
}; 
