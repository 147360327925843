 import React, { useState } from 'react';
import { HelperSchedule } from '../core/helpers/helperSchedule';
import { useEffect } from 'react';
import Helper from '../../../core/helper/helper';
 /**
   * @param {Object} props
   * @param {boolean} props.stepActive 
   * @param {ScheduleData} props.scheduleData 
   * @param {Funtion} props.onStepChanged
   * @param {Funtion} props.onSave
   * @returns 
   */
  const ScheduleCTA = (props) => {
    const ctaLabel = {
      continue: "Continuar",
      confirm: "Confirmar",
      saveContinue: "Guardar e Continuar",
      back: "Voltar atrás",
      registerConfirm: "Registar para Confirmar",
      confirmPayment: "Confirmar e Pagar",
    };
    const sumSchedulePaymentValues = (services) => {
      if (!services || services.length === 0) return 0;
      const total = services.reduce((acc, service) => {
        const value = parseFloat(service.schedule_payment_value) || 0;
        return acc + value;
      }, 0);
      const formattedTotal = total.toFixed(2).replace('.', ',');
      return formattedTotal.endsWith(',00') ? formattedTotal.slice(0, -3) : formattedTotal;
    };

    const [stepActive, setStepActive] = useState(props.stepActive);
    const [scheduleData, setScheduleData] = useState(props.scheduleData);
    const [ctaStyle, setCtaStyle] = useState(`${props.stepActive === 1 ? 'w-100' : 'w-100 w-md-50 ms-md-2 mt-3 mt-md-0'}`);
    const servicesAvailable = props.servicesAvailable;
    const hasPayment = props.hasPayment;
    const [totalValue, setTotalValue] = useState(sumSchedulePaymentValues(props.scheduleData?.selectedServices));
    const [isDisabledScheduleConfirmation, setIsDisabledScheduleConfirmation] = useState(props.isDisabledScheduleConfirmation);

    useEffect(() => {
      setStepActive(props.stepActive);
      setCtaStyle(`${props.stepActive === 1 ? 'w-100' : 'w-100 w-md-50 ms-md-2 mt-3 mt-md-0'}`);
    }, [props.stepActive]);

    useEffect(() => {
      setScheduleData(props.scheduleData);
      setTotalValue(sumSchedulePaymentValues(props.scheduleData?.selectedServices));
    }, [props.scheduleData]);
   
    useEffect(() => {
      setIsDisabledScheduleConfirmation(props.isDisabledScheduleConfirmation);
    }, [props.isDisabledScheduleConfirmation]);

    const handleClickBack = () => {
      let step;
      const hasServiceFixedDate = scheduleData?.selectedServices?.some(service => service.schedule_fixed_start_enabled);
      if (stepActive === 3 && hasServiceFixedDate) {
        step = stepActive - 2;
      } else {
        step = stepActive - 1;
      }
      setStepActive(step);
      props.onStepChanged(step);
    }

    const handleClickContinue = () => {
      let step;
      if (stepActive === 3) {
        props.onSave();
        return;
      }
      const hasServiceFixedDate = scheduleData?.selectedServices?.some(service => service.schedule_fixed_start_enabled);
      if (stepActive === 1 && hasServiceFixedDate) {
        step = stepActive + 2;
      } else {
        step = stepActive + 1;
      }  
      setStepActive(step);
      props.onStepChanged(step);
    }

    const renderBack = () => {
      if (stepActive === 1) {
        return <React.Fragment />;
      }

      return (
        <div className={`cta-button-secondary align-center px-3 w-100 w-md-50 me-md-2`}
          onClick={handleClickBack} role="button">
          {ctaLabel.back}
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="cta-buttons-container mt-5 pb-4 d-block d-md-inline-flex justify-content-center">
          {renderBack()}
          <div
            className={`cta-button-primary align-center px-3 ${ctaStyle} ${
              HelperSchedule.isContinueDisabled(servicesAvailable, scheduleData, stepActive) || isDisabledScheduleConfirmation ? "disabled" : ""
            }`}
            role="button"
            onClick={handleClickContinue}
          >
            {stepActive === 1 ? ctaLabel.saveContinue :
              stepActive === 2 ? ctaLabel.continue :
                Helper.isLiteVersion() ? ctaLabel.registerConfirm :
                  hasPayment ? ctaLabel.confirmPayment + (totalValue && totalValue === '0.00' ? '' : ' ' + totalValue + ' €') :
                    ctaLabel.confirm}
          </div>
        </div>
      </React.Fragment>
    );
  };

  export default ScheduleCTA;