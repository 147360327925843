import React from 'react';
import ShowNotificationButton from '../../button/showNotificationButton';
import { ToastType } from '../../../core/types';

/** 
* * NotificationButtonData definition
* @typedef { import('../../button/showNotificationButton').PropsData }  NotificationButtonData 
*/

/**
 * Props definition
 * @typedef  {Object} Props
 * @property {PropsData} data
 * @property {() => void} goToDetails 
 * @property {() => void} onRead 
 * @property {() => void} onReadCheck
 */

/**
 * PropsData definition
 * @typedef  {Object}  PropsData
 * @property {string}  source 
 * @property {boolean} stateMessage
 * @property {boolean} stateMessage
 * @property {boolean} stateCheck
 * @property {boolean} showUnreadCheck
 * @property {boolean} showUnreadMessage
 * @property {boolean} showInternalNotes
 * @property {boolean} allowApprove
 * @property {boolean} isCompact
 */

/**
 * StateData definition
 * @typedef  {Object}  StateData
 * @property {boolean} showUnreadCheck
 * @property {boolean} showUnreadMessage
 * @property {boolean} showInternalNotes
 * @property {boolean} showPaymentAction
 */

class NotificationWidgets extends React.Component {
  constructor(/** @type {Props} */ props) {
    super(props);
    /** @type {Props} */
    const { data: { showUnreadCheck, showUnreadMessage, showInternalNotes, showPaymentAction }} = this.props;

    /** @type {StateData} */
    this.state = {
      showUnreadCheck,
      showUnreadMessage,
      showInternalNotes,
      showPaymentAction
    }
  }

  componentDidUpdate(/** @type {Props} */ prevProps) {
    if (prevProps !== this.props) {
      /** @type {Props} */
      const { data: { showUnreadCheck, showUnreadMessage, showInternalNotes }} = this.props;
      this.setState({showUnreadCheck, showUnreadMessage, showInternalNotes});
    }
  }

  handlOnRead = () => {
    /** @type {Props} */
    const { data: { source }} = this.props;
    if (source === 'details') {
      this.props.onRead();
    }
  }

  handlOnCheck = () => {
    /** @type {Props} */
    const { data: { source }} = this.props;
    if (source === 'details') {
      this.props.onReadCheck();
    }
  }

  renderAction = () => { 
    /** @type {Props} */
    const { data: {stateAction, source, allowApprove, showPaymentAction }} = this.props;
    if (!stateAction) {
      return <></>;
    }
    /** @type {NotificationButtonData}  */
    const data = {
      type: showPaymentAction ? ToastType.PAYMENT : ToastType.ACTION,
      source,
      unRead: stateAction || showPaymentAction,
      noFocus: !allowApprove && !showPaymentAction,
    };
    return (
      <ShowNotificationButton data={data} goToDetails={() => this.props.goToDetails()}/>
    );
  }

  renderCheck= () => { 
    /** @type {Props} */
    const { data: { stateCheck, source, showPaymentAction }} = this.props;

    /** @type {StateData} */
    const { showUnreadCheck } = this.state;

    if (!stateCheck || showPaymentAction) {
      return <></>;
    }
    /** @type {NotificationButtonData}  */
    const data = {
      type: 'check',
      source,
      unRead: showUnreadCheck,
    };
    return (
      <ShowNotificationButton
        data={data} 
        goToDetails={() => this.props.goToDetails()}
        onReadCheck={() => this.handlOnCheck()}
      />
    );
  }

  renderMessage= () => { 
    /** @type {Props} */
    const { data: { stateMessage, source, fromMessageNotification }} = this.props;

    /** @type {StateData} */
    const { showUnreadMessage } = this.state;

    if (!stateMessage) {
      return <></>;
    }
    /** @type {NotificationButtonData}  */
    const data = {
      type: 'message',
      source,
      unRead: showUnreadMessage,
      fromMessageNotification
    };
    return (
      <ShowNotificationButton
        data={data} 
        goToDetails={() => this.props.goToDetails()}
        onRead={() => this.handlOnRead()}
      />
    );
  }

  renderInternalNotes= () => { 
    /** @type {Props} */
    const { data: { source, isCompact }} = this.props;

    /** @type {StateData} */
    const { showInternalNotes } = this.state;

    if (!showInternalNotes) {
      return <></>;
    }
    /** @type {NotificationButtonData}  */
    const data = {
      type: 'internal',
      source,
      unRead: false,
      isCompact
    };
    return (
      <ShowNotificationButton
        data={data} 
        goToDetails={() => this.props.goToDetails()}
      />
    );
  }
  render() {
    return(
      <React.Fragment>
        {this.renderAction()}
        {this.renderCheck()}
        {this.renderMessage()}
        {this.renderInternalNotes()}
      </React.Fragment>
    );
  }
}
export default NotificationWidgets;
