import React, { Component } from 'react';
import Select, { components } from 'react-select';
import './select-list.style.scss';
import CustomOption from './CustomOption';

class SelectList extends Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();

    const { options, selectedOption, size, isLoading, isDisabled } = this.props;
    this.state = {
      selectedOption: selectedOption ? selectedOption : null,
      options: options,
      isLoading: options && !isLoading ? false : true,
      isDisabled: isDisabled,
      height: size === 'small' ? '24px' : '32px'
    }
  }

  componentDidMount() {
    this.ref.current.tabIndex = this.state.isDisabled ? -1 : 0;
  }

  componentDidUpdate(prevProps) {
    const { options, selectedOption, isLoading, isDisabled } = this.props;
    if (prevProps.options !== options) {
      this.setState({
        options: options,
        isLoading: options && !isLoading ? false : true,
      }); 
    }
    if (prevProps.selectedOption !== selectedOption) {
      this.setState({ selectedOption: selectedOption });
    }

    if (prevProps.isDisabled !== isDisabled) {
      this.ref.current.tabIndex = isDisabled ? -1 : 0;
      this.setState({ isDisabled });
    }
  }

  handleChange = selectedOption => {
    if (selectedOption) {
      this.setState(
        { selectedOption },
        () => this.props.onChange(this.state.selectedOption)
      );
    } else {
      this.setState({ selectedOption: null });
      this.props.onChange(null)
    }
  };
  
  renderLabel = (title) => {
    if (!title) {
      return <></>;
    }
    return <p className="input-label">{title}</p>;
  }
  
  render() {
    const { options, selectedOption, isLoading, height, isDisabled } = this.state;
    const { isRemovable, onRemoveOption } = this.props;
    const styleObject = {
      singleValue: (styles) => ({
        ...styles,
        ":focus": {
          ...styles[":focus"],
          fontSize: "16px",
        },
        ":active": {
          ...styles[":active"],
          fontSize: "16px",
        },
      }),
      input: (provided, state) => ({
        ...provided,
        fontSize: '16px', // Garante 16px no input por padrão
        isDisabled: state.isDisabled,
        PointerEvents: "none",
        color: state.isDisabled ? "#22272a" : "#22272a",
      }),
      control: (styles, state) => ({ 
        ...styles,
        color: state.isDisabled ? "#22272a" : "#22272a",
        fontSize: state.isFocused ? '16px' : '14px',
        fontFamily: 'SourceSansPro-Regular',
        cursor: 'pointer',
        minHeight: height,
        maxHeight: height,
        border: selectedOption ? '1px solid #b7cde3' : 0,
        tabIndex: state.isDisabled ? -1 : 0,
        backgroundColor: state.isDisabled ? "#FFFFFF" : "#FFFFFF",
      }),
      indicatorsContainer: styles => ({
        ...styles,
        '>div': {
          ...styles['>div'],
          padding: '7px 6px',
        },
        maxHeight: height,
      }),
      valueContainer: styles => ({
        ...styles,
        maxHeight: height,
      }),
      indicatorSeparator: styles => ({
        ...styles,
        color: 'black',
        display: 'none',

      }),
      clearIndicator: styles => ({
        ...styles,
        color: 'black'
      }),
      dropdownIndicator: styles => ({
        ...styles,
        color: 'black'
      }),
      menu: (styles) => ({
        ...styles,
        fontSize: 14,
        fontFamily: "SourceSansPro-light",
        backgroundColor: "white",
        padding: "6px 8px",
        border: "none",
        borderRadius: "8px",
        borderTopRightRadius: "0",
        borderTopLeftRadius: "0",
        boxShadow: "0 2px 15px #a5adbd",
        marginTop: "-1px",
        color: "#22272a",
        "@media (min-width: 768px)": {
          ...styles["media (max-width: 768px)"],
          fontSize: 16,
        },
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: "200px", // Set a maximum height
        overflowY: "auto", // Enable vertical scrolling
      }),
      option: (styles, state) => ({
        ...styles,
        fontSize: 14,
        fontFamily: "SourceSansPro-Light",
        cursor: "pointer",
        height: "38px",
        borderBottom: "1px solid rgba(165, 173, 189, 0.3)",
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#f5f6fa",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: "#f5f6fa",
        },
        ":focus": {
          ...styles[":focus"],
          backgroundColor: "#f5f6fa",
        },
        backgroundColor: state.isSelected || state.isFocus ? "#f5f6fa" : "white",
        color: "#22272a",
      }),
    };

    return (
      <div className='select-list-container w-100'>
          {this.renderLabel(this.props.title)}
        <div className="select-list-row-container">
          <div className={`select-list ${this.props.required ? 'required' : ''}`}>
            <Select
              ref={this.ref}
              value={selectedOption}
              onChange={this.handleChange}
              placeholder={this.props.placeholder || 'Selecione...'}
              options={options}
              isClearable={this.props.isClearable}
              isRemovable={isRemovable}
              clearValue={this.handleChange}
              isLoading={isLoading}
              isDisabled={isLoading || isDisabled}
              tabIndex={isDisabled ? -1 : 0}
              theme={theme => ({
                ...theme,
                borderRadius: 8,
                border: selectedOption ? '1px solid #f5f6fa' : 0,
                height,
                colors: {
                  ...theme.colors,
                  primary25: '#f5f6fa',
                  primary: '#f5f6fa',
                },
              })}
              styles={styleObject}
              // components={{ Option: isRemovable ? this.CustomOption : components.Option }}
              components={{
                Option: (props) => isRemovable ? (
                  <CustomOption {...props} onRemove={onRemoveOption} />
                ) : <components.Option {...props} />,
                // Control: (props) => <CustomControl {...props} isDisabled={isDisabled} />,
                // Input: (props) => <CustomInput {...props} isDisabled={isDisabled} />
              }}
            />
          </div>
        </div>
      </div>
    );
  }

}
export default SelectList;

// const CustomControl = ({ isDisabled, ...props }) => {
//   return (
//     <components.Control
//       {...props}
//       innerRef={(node) => {
//         if (node) {
//           // node.tabIndex = isDisabled ? -1 : 0; // Corrected scope issue
//           node.isDisabled = isDisabled
//         }
//       }}
//     />
//   );
// };
// const CustomInput = ({ isDisabled, ...props }) => {
//   return (
//     <components.Control
//       {...props}
//       innerRef={(node) => {
//         if (node) {
//           // node.tabIndex = isDisabled ? -1 : 0; // Corrected scope issue
//           node.isDisabled = isDisabled
//         }
//       }}
//     />
//   );
// };
