/**
 * @param {Object} styles - The styles object for customizing the React Select components.
 * @param {Object} state - The state object for React Select components.
 */
export const styleObject = {
  singleValue: (styles, state) => ({
    ...styles,
    // fontSize: "16px",
    fontSize: state.isFocused ? '16px' : '14px',
    ":focus": {
      ...styles[":focus"],
      fontSize: "16px",
    },
    ":active": {
      ...styles[":active"],
      fontSize: "16px",
    },
  }),
  container: (provided) => ({
    ...provided,
    zIndex: 4,
    border: "2px solid #384654",
    // boxShadow: "0 -4px 8px 15px rgba(34, 39, 42, 0.9)",
  }),
  control: (styles, state) => ({
    ...styles,
    border: "none",
    // border: "2px solid #384654",
    // borderBottom: state.isDisabled ? "none" : "1px solid white",
    borderRadius: 0,
    boxShadow: "none",
    color: "white",
    fontSize: state.isFocused ? '16px' : '14px',
    tabIndex: state.isDisabled ? -1 : 0,
    fontFamily: "SourceSansPro-Regular",
    cursor: "pointer",
    minHeight: "40px",
    // height: "40px",
    // maxHeight: "40px",
    order: 0,
    // paddingBottom: "8px",
    padding: "4px",
    "@media (min-width: 768px)": {
      ...styles["media (max-width: 768px)"],
      fontSize: 22,
    },
    backgroundColor: state.isDisabled ? "#22272a" : "#22272a",
    ":hover": {
      ...styles[":hover"],
      // borderBottom: "1px solid #ff882b",
    },
    ":focus": {
      ...styles[":focus"],
      fontSize: "16px",
      // borderBottom: state.isDisabled ? "none" : "1px solid #ff882b",
    },
    ":active": {
      ...styles[":active"],
      fontSize: "16px"
      // borderBottom: state.isDisabled ? "none" : "1px solid #ff882b",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "2px 24px 2px 8px",
    flexDirection: "row-reverse",
    flexWrap: "nowrap"
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: "SourceSansPro-Light",
    fontSize: "16px",
    color: "white",
    textAlign: "left",
    width: "100%",
    paddingLeft: "36px",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#31383f",
    color: "white",
    borderRadius: "4px",
    fontSize: "14px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
    fontSize: "14px",
  }),
  input: (styles) => ({
    ...styles,
    color: "white",
    fontSize: "16px",
    fontFamily: "SourceSansPro-Regular",
    textAlign: "left",
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    ">div": {
      ...styles[">div"],
      padding: "0",
      right: "7px",
      ">img": {
        filter:
          "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(265deg) brightness(105%) contrast(101%)",
        ":hover": {
          filter:
            "brightness(0) saturate(100%) invert(70%) sepia(30%) saturate(4671%) hue-rotate(337deg) brightness(99%) contrast(104%)",
        },
      },
    },
    ...(state.isFocused && {
      ">div": {
        ...styles[">div"],
        padding: "0",
        ">img": {
          filter:
            "brightness(0) saturate(100%) invert(70%) sepia(30%) saturate(4671%) hue-rotate(337deg) brightness(99%) contrast(104%)",
        },
      },
    }),
    order: -1,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: "white",
    display: "none",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "white",
    order: 2,
    position: "absolute",
    right: 0,
    width: "16px",
    height: "16px",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    order: -1,
    width: "32px",
    height: "32px",
    ":active": {
      ...styles[":active"],
      filter: state.isDisabled
        ? "brightness(0) saturate(100%) invert(93%) sepia(8%) saturate(514%) hue-rotate(199deg) brightness(104%) contrast(95%)"
        : "brightness(0) saturate(100%) invert(70%) sepia(30%) saturate(4671%) hue-rotate(337deg) brightness(99%) contrast(104%)",
    },
    ":focus": {
      ...styles[":focus"],
      filter: state.isDisabled
        ? "brightness(0) saturate(100%) invert(93%) sepia(8%) saturate(514%) hue-rotate(199deg) brightness(104%) contrast(95%)"
        : "brightness(0) saturate(100%) invert(70%) sepia(30%) saturate(4671%) hue-rotate(337deg) brightness(99%) contrast(104%)",
    },
    // filter: state.isDisabled
    //   ? "brightness(0) saturate(100%) invert(93%) sepia(8%) saturate(514%) hue-rotate(199deg) brightness(104%) contrast(95%)"
    //   : "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(265deg) brightness(105%) contrast(101%)",
  }),
  menu: (styles) => ({
    ...styles,
    fontSize: 14,
    fontFamily: "SourceSansPro-light",
    backgroundColor: "#31383f",
    padding: "6px 8px",
    border: "none",
    borderRadius: "8px",
    borderTopRightRadius: "0",
    borderTopLeftRadius: "0",
    boxShadow: "box-shadow: 0 2px 15px 2px #000000e6",
    marginTop: "-1px",
    color: "white",
    zIndex: 1000,
    position: "absolute"
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px", // Set a maximum height
    overflowY: "auto", // Enable vertical scrolling
    zIndex: 1000
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize: 14,
    fontFamily: "SourceSansPro-Light",
    cursor: "pointer",
    height: "38px",
    borderBottom: "1px solid rgba(165, 173, 189, 0.3)",
    ":hover": {
      ...styles[":hover"],
      backgroundColor: "#22272a",
    },
    ":active": {
      ...styles[":active"],
      backgroundColor: "#22272a",
    },
    ":focus": {
      ...styles[":focus"],
      backgroundColor: "#22272a",
    },
    backgroundColor: state.isSelected || state.isFocus ? "#22272a" : "#31383f",
    color: "white",
  }),
};
