const PICTO_CATEGORIES = {
  carrocaria: require('assets/images/picto_categories/PictoCarrocaria_2pt.svg'),
  chassis_Rodas: require('assets/images/picto_categories/PictoChassisRodas_2pt.svg'),
  habitaculo: require('assets/images/picto_categories/PictoHabitaculo_2pt.svg'),
  moto_embraiagem: require('assets/images/picto_categories/PictoMotorEmbraiagem_2pt.svg'),
  quadrante: require('assets/images/picto_categories/PictoQuadrante_2pt.svg'),
  revisao_ipo: require('assets/images/picto_categories/PictoRevisaoIPO_2pt.svg'),
  pintura: require('assets/images/picto_categories/PictoPintura.svg'),
  outros: require('assets/images/picto_categories/PictoOutros.svg'),
};

const CATEGORIES = [
  { id: 1, icon: PICTO_CATEGORIES.revisao_ipo },
  { id: 2, icon: PICTO_CATEGORIES.moto_embraiagem },
  { id: 3, icon: PICTO_CATEGORIES.chassis_Rodas },
  { id: 4, icon: PICTO_CATEGORIES.carrocaria },
  { id: 5, icon: PICTO_CATEGORIES.quadrante },
  { id: 6, icon: PICTO_CATEGORIES.habitaculo },
  { id: 7, icon: PICTO_CATEGORIES.pintura },
  { id: 8, icon: PICTO_CATEGORIES.outros },
];

const SIDEBAR = {
  home_old: require('assets/images/picto_sidebar/PictoServices.svg'),
  home: require('assets/images/picto_sidebar/PictoHome.svg'),
  close: require('assets/images/picto_gene/BotaoClose.svg'),
  scheduleActive: require('assets/images/picto_sidebar/PictoScheduleActive.svg'),
  schedule: require('assets/images/picto_sidebar/PictoSchedule.svg'),
  equipment: require('assets/images/picto_sidebar/PictoEquipment.svg'),
  equipmentActive: require('assets/images/picto_sidebar/PictoEquipmentActive.svg'),
  equipment2: require('assets/images/picto_sidebar/PictoEquipamento2.svg'),
  equipment2Active: require('assets/images/picto_sidebar/PictoEquipamento2Active.svg'),
  profileActive: require('assets/images/picto_sidebar/PictoPerfilActive.svg'),
  profile: require('assets/images/picto_sidebar/PictoPerfil.svg'),
  editProfile: require('assets/images/picto_sidebar/PictoEditar.svg'),
  editProfileBlue: require('assets/images/picto_sidebar/PictoEditarBlue.svg'),
  editWhite: require('assets/images/picto_sidebar/PictoEditar.svg'),
  feedback: require('assets/images/picto_sidebar/PictoFeedback.svg'),
  feedbackBlue: require('assets/images/picto_sidebar/PictoMailBlue.svg'),
  suporte: require('assets/images/picto_sidebar/PictoSuporte.svg'),
  suporteBlue: require('assets/images/picto_sidebar/PictoSuporteBlue.svg'),
  arrow: require('assets/images/picto_sidebar/SetaDireita.svg'),
  arrowBack: require('assets/images/picto_sidebar/arrowBack.svg'),
  serviceDetails: require('assets/images/picto_sidebar/PictoEquipmentService.svg'),
  adicionar_equipment: require('assets/images/picto_sidebar/PictoAdicionarEquipment.svg'),
  alterar_password: require('assets/images/picto_sidebar/PictoAlterarPassword.svg'),
  lockerBlue: require('assets/images/picto_sidebar/PictoLockerBlue.svg'),
  keymasterBlue: require('assets/images/picto_sidebar/PictoKeymasterBlue.svg'),
  store: require('assets/images/picto_sidebar/store.svg'),
  userSettings: require('assets/images/picto_sidebar/PictoProfileSettings.svg'),
  iconPlusCircle: require('assets/images/picto_gene/plus-circle.svg'),
  videoDemoIcon: require('assets/images/picto_sidebar/videoDemo.svg'),
  prestadores: require('assets/images/picto_sidebar/prestadores.svg'),
  calendar: require('assets/images/picto_sidebar/calendar.svg'),
};

const SERVICE = {
  scheduled: require('assets/images/picto_services/PictoCalendarIn.svg'),
  checkin: require('assets/images/picto_services/PictoEntrada.svg'),
  serviceActive: require('assets/images/picto_services/PictoInvoice.svg'),
  toBeDefine: require('assets/images/picto_services/PictoClock.svg'),
  checkout: require('assets/images/picto_services/PictoCheckout.svg'),
  scheduledOut: require('assets/images/picto_services/PictoCalendarOut.svg'),
  calendar: require('assets/images/picto_services/PictoCalendar.svg'),
  // ######
  service: require('assets/images/picto_services/PictoServicesWhite.svg'),
  checkinActive: require('assets/images/picto_services/PictoEntradaActivo.svg'),
  checkinActiveTech: require('assets/images/picto_services/PictoEntradaActivoDevice.svg'),
  checkinDisable: require('assets/images/picto_services/PictoEntradaInactivo.svg'),
  checkinDisableTech: require('assets/images/picto_services/PictoEntradaInactivoTech.svg'),
  checkoutTech: require('assets/images/picto_services/PictoSaidaActivoDevice.svg'),
  checkoutDisable: require('assets/images/picto_services/PictoSaidaInactivo.svg'),
  checkoutDisableTech: require('assets/images/picto_services/PictoSaidaInactivoTech.svg'),
  valorTotal: require('assets/images/picto_services/PictoValorTotal.svg'),
  checkmark: require('assets/images/picto_services/PictoCheckmark.svg'),
  maquina_foto: require('assets/images/picto_gene/PictoMaquina.svg'),
  maquina_foto_std: require('assets/images/picto_gene/PictoMaquina.svg'),
  defaultImage: require('assets/images/picto_services/PictoDefaultItem.svg'),
  defaultImageTech: require('assets/images/picto_services/PictoDefaultItemTech.svg'),
  approveCostButton: require('assets/images/picto_services/PictoAprovarOrcamento.svg'),
  serviceChecked: require('assets/images/picto_services/serviceChecked.svg'),
  serviceNotChecked: require('assets/images/picto_services/serviceNotChecked.svg'),
};

const GENERAL = {
  location: require('assets/images/picto_gene/PictoLocalizacao.svg'),
  defaultOn: require('assets/images/picto_gene/DefaultOption.svg'),
  defaultOff: require('assets/images/picto_gene/DefaultOptionOff.svg'),
  invoiceOn: require('assets/images/picto_gene/invoiceOn.svg'),
  invoiceOff: require('assets/images/picto_gene/invoiceOff.svg'),
  arrowDown: require('assets/images/picto_gene/ArrowDown.svg'),
  arrowDown_Rotated: require('assets/images/picto_gene/ArrowDown_Rotated.svg'),
  arrowUp: require('assets/images/picto_gene/ArrowUP.svg'),
  arrowRight: require('assets/images/picto_gene/arrowRight.svg'),
  arrowBigRight: require('assets/images/picto_gene/arrowBigRight.svg'),
  arrowLeft: require('assets/images/picto_gene/arrowLeftBlue.svg'),
  add: require('assets/images/picto_gene/PictoPlus.svg'),
  addBlue: require('assets/images/picto_gene/PictoPlusBlue.svg'),
  eyeButton: require('assets/images/picto_gene/eye.svg'),
  eyeHide: require('assets/images/picto_gene/eyeHide.svg'),
  ratingIcon: require('assets/images/picto_gene/PictoStar2.svg'),
  closeIcon: require('assets/images/picto_gene/close.svg'),
  closeWhite: require('assets/images/picto_gene/PictoCloseWhite.svg'),
  minimizeIcon: require('assets/images/picto_gene/minimize.svg'),
  minimizeBlueIcon: require('assets/images/picto_gene/minimizeBlue.svg'),
  cardCorner: require('assets/images/picto_gene/dobraCardFeedback.svg'),
  starFill: require('assets/images/picto_gene/starFill.svg'),
  starFillOrange: require('assets/images/picto_gene/starFillOrange.svg'),
  starBallon: require('assets/images/picto_gene/starBallon.svg'),
  arrowUpBlue: require('assets/images/picto_gene/arrowUpBlue.svg'),
  arrowUpWhite: require('assets/images/picto_gene/arrowUpWhite.svg'),
  plusBlue: require('assets/images/picto_gene/plusBlue.svg'),
  searchWhite: require('assets/images/picto_gene/searchWhite.svg'),
  searchOrange: require('assets/images/picto_gene/searchOrange.svg'),
  closeOrange: require('assets/images/picto_gene/closeOrange.svg'),
  checkGreen: require('assets/images/picto_gene/checkGreen.svg'),
  listIcon: require('assets/images/picto_gene/PictoList.svg'),
  notesIcon: require('assets/images/picto_gene/PictoNotes.svg'),
  internal: require('assets/images/picto_gene/PictoNotesNoti.svg'),
  mediaIcon: require('assets/images/picto_gene/mediaIcon.svg'),
  docsIcon: require('assets/images/picto_gene/docsIcon.svg'),
  downloadBlue: require('assets/images/picto_gene/downloadBlue.svg'),
  uploadBlue: require('assets/images/picto_gene/uploadBlue.svg'),
  editBlue: require('assets/images/picto_sidebar/PictoEditarBlue.svg'),
  deleteIcon: require('assets/images/picto_gene/deleteIcon.svg'),
  whatsapp: require('assets/images/picto_gene/whatsapp.svg'),
  phonecall: require('assets/images/picto_gene/phonecall.svg'),
  playstore: require('assets/images/picto_gene/playstore.svg'),
  callAppStoreBig: require('assets/images/picto_gene/callAppStoreBig.png'),
  callAppStore: require('assets/images/picto_gene/callAppStore.png'),
  warning: require('assets/images/picto_gene/warning.svg'),
  delete: require('assets/images/picto_gene/delete.svg'),
  share: require('assets/images/picto_gene/share.svg'),
  message: require('assets/images/picto_gene/icon-message.svg'),
  messageCounter: require('assets/images/picto_gene/icon-message-counter.svg'),
  check: require('assets/images/picto_gene/icon-success.svg'),
  action: require('assets/images/picto_gene/action-red-icon.svg'),
  payment: require('assets/images/picto_gene/action-red-icon.svg'),
  send: require('assets/images/picto_gene/send-icon.svg'),
  save: require('assets/images/picto_gene/saveIcon.svg'),
  calendarEdit: require('assets/images/picto_gene/calendarEdit.svg'),
  notification: require('assets/images/picto_gene/notification.svg'),
  // new icons
  iconClient: require('assets/images/svgs/icon-km-cliente.svg'),
  iconArrowUp: require('assets/images/svgs/icons/arrow-up.svg'),
  iconSmallArrowUp: require('assets/images/svgs/icons/small-arrow-up.svg'),
  iconSmallArrowDown: require('assets/images/svgs/icons/small-arrow-down.svg'),
  iconPlus: require('assets/images/svgs/icons/generic-plus.svg'),
  iconLocation: require('assets/images/svgs/icons/location.svg'),
  iconPhone: require('assets/images/svgs/icons/phone.svg'),
  iconWebsite: require('assets/images/svgs/icons/website.svg'),
  iconClose: require('assets/images/svgs/icons/cross.svg'),
  iconSearch: require('assets/images/svgs/icons/search.svg'),
  logoKMclient: require('assets/images/svgs/logo-empresa.svg'),
  defaultCompanyLogo: require('assets/images/pngs/logotipo-cinza.png'),
  iconClock_oldDate: require('assets/images/picto_gene/clock_oldDate.svg'),
  iconClock: require('assets/images/picto_gene/clock.svg'),
  iconCalendarScheduled_oldDate: require('assets/images/picto_gene/calendar-scheduled_oldDate.svg'),
  iconCalendarScheduled: require('assets/images/picto_gene/calendar-scheduled.svg'),
  // Login 
  iconApple: require('assets/images/picto_gene/apple-logo.svg'),
  iconGoogle: require('assets/images/picto_gene/google-logo.svg'),
  iconOutlook: require('assets/images/picto_gene/outlook-logo.svg'),
  iconSquareChecked: require('assets/images/picto_gene/squareChecked.svg'),
  iconSquareUnchecked: require('assets/images/picto_gene/squareUnchecked.svg'),
  flagPortugal: require('assets/images/picto_gene/portugal.svg'),
  iconVideoCall: require('assets/images/picto_gene/camera-video.svg'),
};

const PAYMENT = {
  mbway: require('assets/images/picto_gene/mbway.svg'),
  mb: require('assets/images/picto_gene/mb.svg'),
  visa: require('assets/images/picto_gene/visa.svg'),
  mastercard: require('assets/images/picto_gene/mastercard.svg'),
  creditcard: require('assets/images/picto_gene/creditcard.svg'),
  maestro: require('assets/images/picto_gene/maestro.svg'),
}

const DELETEACCOUNT = {
  help_step_1: require('assets/images/pngs/helpDeleteAccount/help_step_1.png'),
  help_step_2: require('assets/images/pngs/helpDeleteAccount/help_step_2.png'),
  help_step_3: require('assets/images/pngs/helpDeleteAccount/help_step_3.png'),
};

const ASSETS = {
  clientLogo: require('assets/images/svgs/logo.svg'),
  vertical: {
    icon: (/** @type {string} */ type) => {
      return require(`assets/images/verticais/icons/${type || 'DEFAULT'}/iconequip.svg`);
    },
  }
}

module.exports = {
  CATEGORIES, PICTO_CATEGORIES, SIDEBAR, SERVICE, GENERAL, ASSETS, DELETEACCOUNT, PAYMENT
};

