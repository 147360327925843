import React from "react";
import Labels from "../../../variables/labels";
import CompanyCardList from "../../../components/sidebar/CompanyCardList";
import LocalData from "../../../core/localData";
import CompanyService from "../../../core/services/company.service";
import { CategoryService } from "../../../core/services/category.service";
import ProvidersPage from "./ProvidersPage/ProvidersPage";
import { GeoLocationService } from "../../../core/services/geo-localization.service";
import Emitter from "../../../core/services";
import Loader from "../../../components/loader/loader";
import Helper from "../../../core/helper/helper";
import Modal, { Information } from "../../../components/modal/modal";
import { GENERAL } from "../../../assets/images/images";
import Cookies from "universal-cookie";
import ExpandedCompanyDetailsCard from "./ExpandedCompanyDetails/ExpandedCompanyDetails";
import SidebarHelper from "../../../core/helper/sidebarHelper";

/**
 * @typedef {Object} CompaniesViewState
 * @property {number} addElementHeight
 * @property {number} gridHeight
 * @property {number} scrollY
 * @property {boolean} addActivated
 * @property {boolean} inputOnFocus
 * @property {boolean} providerViewActive
 * @property {boolean} showSearchBar
 * @property {string} searchTerm
 * @property {string} geoLocalization
 * @property {Category} selectedCategory
 * @property {Category[]} categories
 * @property {Company[]} companies
 * @property {Company[]} userCompanies
 * @property {Option[]} companyOptions
 * @property {Option[]} selectedOptions
 */

class Companies extends React.Component {
  /** @param {Object} props */
  constructor(props) {
    super(props);
    /** @type {React.RefObject<any>} */
    this.refInput = React.createRef();
    this.refModal = React.createRef();
    this.cookies = new Cookies();
    this.observer = null;
    this.isIOS = Helper.isIOSWebview() || Helper.isIOS();

    /** @type {CompaniesViewState} */
    this.state = {
      isMobile: window.innerWidth <= 768,
      addElementHeight: 120,
      gridHeight: null,
      addActivated: false,
      searchTerm: null,
      inputOnFocus: false,
      categories: LocalData.categories,
      companies: [],
      userCompanies: [],
      userCompaniesLoading: !Helper.isLiteVersion() && !LocalData.companies,
      currentCompany: LocalData.configData?.companyDetails,
      companyOptions: undefined,
      selectedCategory: undefined,
      searchCompanySectionActive: false,
      geoLocalization: undefined,
      selectedOptions: [],
      scrollY: 0,
      showSearchBar: false,
      scrolling: false,
      fixSearchBottom: false,
      isNavigating: false,
      listFixedTop: true,
    };
  }

  componentWillUnmount() {
    Emitter.off("COMPANIES_LOADED");
    Emitter.off("GO_BACK_PROVIDERS");
    delete window.receiveLocation;
    window.removeEventListener('resize', this.handleResize);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  componentDidMount() {
    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.floatKeyboard.postMessage({value: true});
      } catch (error) {
        console.error(error);
      }
    }
    Emitter.on("COMPANIES_LOADED", () => {
      this.setState({
        userCompanies: LocalData.companies || [],
        userCompaniesLoading: false,
      });
      this.menuScrollListener();
    });
    Emitter.on("GO_BACK_PROVIDERS", () => {
      this.setState({ listFixedTop: true });
      this.goToSection(1);
    })
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    if (!Helper.isLiteVersion() && !LocalData.companies) {
      CompanyService.getUserCompaniesList();
    } else {
      this.setState({ userCompanies: [] });
      setTimeout(() => {
        this.menuScrollListener();
      }, 250);
    }
    this.menuElement.style.overflowY = "hidden";
    this.gridElement.style.overflowY = "auto";

    if (!LocalData.categories?.length) {
      this.getCategories();
    }

    window.receiveLocation = (geoLocalization) => {
      this.setState({ geoLocalization });
      this.getNewCompanies(this.state.selectedCategory);
    }

    this.getGeolocalization();
    // this.menuScrollListener();
    // this.addSnapObserver();
  }

  activateScrollSnap = () => {
    const container = document.getElementById('menu-key-container');
    container.classList.add("snap-y-proximity");
    // if (Helper.companyDomain()?.includes("checkpoint")) {
    // }
  }

  stopScrolling = () => {
    const container = document.getElementById('menu-key-container');
    container.style.overflowY = "hidden";
    if (!this.isIOS) {
      container.style.marginRight = "6px";
    }
    setTimeout(() => {
      container.style.overflowY = "auto";
      if (!this.isIOS) {
        container.style.marginRight = "0px";
      }
    }, 1);
  }

  menuScrollListener = () => {
    const container = document.getElementById('menu-key-container');
    let lastScrollPosition = 0;
     
    const handleScroll = () => {
      const section2 = document.getElementById('section-2');
      const section1 = document.getElementById('section-1');
      const swiperTabs = document.getElementById('swiper-companies-container');
      
      if (!section2 || !section1 || !container || !swiperTabs) return; // Ensure elements are present
      const section2Top = section2?.offsetTop - container?.scrollTop;

      if (this.state.listFixedTop && (container.scrollTop > lastScrollPosition) && section2Top < -24) {
        this.setState({ listFixedTop: false });
      }

      if ((section2Top < ((container.clientHeight * 2) / 3)) && (container.scrollTop > lastScrollPosition) && !this.state.isNavigating) {
        this.activateScrollSnap();
      } else {
        container.classList.remove("snap-y-proximity");
      }

      if (section2Top >= -24 && (container.scrollTop < lastScrollPosition) && !this.state.listFixedTop) {
        this.stopScrolling();
        this.goToSection(2, "smooth");
        this.setState({ listFixedTop: true });
      }

      if (section2Top <= 8) {
        this.setState({ isNavigating: false });
        container.classList.remove("snap-y-proximity");
        if (!this.state.searchCompanySectionActive) {
          this.stopScrolling();
          swiperTabs.classList.add("fixed");
          Emitter.emit("SIDEBAR_BUTTON", 0);
          this.setState({ searchCompanySectionActive: true, fixSearchBottom: false });
        }

        // You can perform any action here
      }
      // else if (section1Bottom > (bottomOfDrawerView > 100 ? bottomOfDrawerView - 100 : bottomOfDrawerView)) {
      //   if (!this.state.fixSearchBottom && !this.state.searchCompanySectionActive) {
      //     swiperTabs.classList.remove("fixed");
      //     this.setState({searchCompanySectionActive: false, fixSearchBottom: true});
      //   }
      //   // You can perform actions when the section is no longer visible within the container view
      // }
      else {
        if (this.state.searchCompanySectionActive || this.state.fixSearchBottom) {
          swiperTabs.classList.remove("fixed");
          this.setState({ searchCompanySectionActive: false, fixSearchBottom: false });
          Emitter.emit("SIDEBAR_BUTTON", undefined);
          container.classList.remove("snap-y-proximity");
        }
      }

      SidebarHelper.checkBoxShadow('menu-key-container');
       // Update last scroll position
      lastScrollPosition = container.scrollTop;
  
    };

    if (!Helper.isLiteVersion()) {
      handleScroll();
    }

    container.addEventListener('scroll', handleScroll);
  }


  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  /**
   * Retrieves categories and updates the state.
   * @returns {Promise<void>}
   */
  getCategories = async () => {
    try {
      const categories = await CategoryService.fetchCategories();
      this.setState({ categories });
    } catch (error) {
      console.error("Error in getCategories:", error);
    }
  };

  /**
   * Retrieves companies for a specific category and updates the state.
   * @param {string} category - The category to fetch companies for.
   * @returns {Promise<void>}
   */
  getNewCompanies = async (category) => {
    const { geoLocalization, selectedCategory } = this.state;
    try {
      const companies = await CompanyService.fetchNewCompanies(category, geoLocalization);
      this.setState({
        companies,
        companyOptions: CompanyService.setSuggestions(companies, selectedCategory),
      });
    } catch (error) {
      // Handle the error, e.g., showing an error message
      console.error("Error in component:", error);
    }
  };

  getGeolocalization = () => {
    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.requestLocation.postMessage({});
      } catch (error) {
        console.error(error);
      }
    } else {
      this.requestLocationPermissions();
    }
  };

  requestLocationPermissions = () => {
    if (this.cookies.get('readLocationRequest', Helper.cookiesConfig) === 'true') {
      this.getBrowserLocation();
      return;
    }
    //setTimeout(() => { this.getBrowserLocation(); }, 2000);
    this.renderModal(
      <Information
        title={Labels.modal.modalLocation.title}
        text={Labels.modal.modalLocation.text}
        icon={GENERAL.iconLocation}
        onClick={() => {
          this.cookies.set('readLocationRequest', true, Helper.cookiesConfig);
          this.refModal.current.closeModal();
          this.getBrowserLocation();
        }}
        />
    );
  }

  getBrowserLocation = async () => {
    //this.refModal.current.closeModal();
    try {
      this.getNewCompanies(this.state.selectedCategory);
      const geoLocalization = await GeoLocationService.getGeolocation();
      if (geoLocalization){
        this.setState({ geoLocalization });
        this.cookies.set('readLocationRequest', true, Helper.cookiesConfig);
        this.refModal.current.closeModal();
        setTimeout(() => {
          this.getNewCompanies(this.state.selectedCategory);
        }, 100);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  renderCompanyCardList = () => {
    const { addActivated, userCompanies } = this.state;

    return (
      <CompanyCardList
        addActivated={addActivated}
        companies={userCompanies}
      />
    );
  };

  handleClickBack = () => {
    this.setState({ addActivated: false }, () => this.setInitialSectionHeights());
  };

  handleGoBackToCategories = () => {
    this.setState({
      selectedCategory: undefined,
      providerViewActive: false,
      selectedOptions: [],
    });
    this.getNewCompanies();
    if (this.swiperInstance) {
      this.swiperInstance.slideTo(1);
    }
  };

  handleOnSelectCategory = () => {
    this.setState({ selectedCategory: undefined }, () => this.getNewCompanies());
  };

  handleAddCompany = (addActivated = true) => {
    this.setState({ addActivated });
    if (this.swiperInstance) {
      this.swiperInstance.slideTo(addActivated ? 1 : 0);
    }
  };

  /** @param {Category} selectedCategory */
  handleCategorySelected = (selectedCategory) => {
    this.getNewCompanies(selectedCategory);
    const selectedOptions = [
      {
        value: selectedCategory.key,
        label: selectedCategory.name,
        isDisabled: true,
      },
    ];
    this.setState({
      selectedCategory,
      providerViewActive: true,
      selectedOptions,
    });
    this.goToSection(2);
  };

  renderDefaultView = () => {
    const { userCompaniesLoading } = this.state;
    const renderLoader = () => {
      if (userCompaniesLoading) {
        return (
          <div className="w-100">
            <Loader message="" inverted={false} local={true} big={false}></Loader>
          </div>
        );
      }
      return <React.Fragment />;
    };

    return (
      <React.Fragment>
        <div className="user-companies-container">
          <div
            className="anchor companies-top-container pt-4 h-auto pb-2 px-4 box-shadow"
            ref={(infoElement) => {
              this.infoElement = infoElement;
            }}
          >
            <p>{Labels.sidebarCompanies.message}</p>
          </div>
          <div
            className={`grid-scroll-container ${
              Helper.isAndroidWebview() || Helper.isIOSWebview() ? "" : "browser"
            }`}
            ref={(gridElement) => {
              this.gridElement = gridElement;
            }}
          >
            <div className="expanded-company-container">
              <ExpandedCompanyDetailsCard
                company={this.state.currentCompany}
                isCurrentCompany={true}
                onClose={() => {}}
                history={this.props.history}
              />
            </div>
            {(Helper.isLiteVersion() || Helper.isDemo()) ? null : (
              <div
                className="grid-container--companies px-0 pb-4 pt-0 justify-content-between"
                id="grid-companies"
              >
                {this.renderCompanyCardList()}
              </div>
            )}
            {/* <div className="scroll-base-container"></div> */}
          </div>
          {renderLoader()}
        </div>
      </React.Fragment>
    );
  };

  renderNewCompanySection = () => {
    const {
      searchCompanySectionActive,
      fixSearchBottom,
      companyOptions,
      companies,
      selectedOptions,
      selectedCategory,
      categories,
    } = this.state;
    
    return !categories?.length ? null : (
      <div
        className={`slide-in-container ${
          searchCompanySectionActive ? "fixed" : fixSearchBottom ? "bottom-fixed" : ""
        } ${Helper.isAndroidWebview() || Helper.isIOSWebview() ? "" : "browser"}`}
        id="slide-in-container"
      >
        <ProvidersPage
          categories={categories}
          onGoBack={() => this.goToSection(1)}
          onGoActive={() => this.goToSection(2)}
          onUnselectCategory={this.handleOnSelectCategory}
          suggestions={companyOptions}
          selectedOptions={selectedOptions}
          // selectedOptions={searchCompanySectionActive ? selectedOptions : []}
          companies={companies}
          isDisabled={!searchCompanySectionActive}
          selectedCategory={selectedCategory}
          onCategorySelected={this.handleCategorySelected}
          styleClass={`${
            searchCompanySectionActive ? "fixed" : fixSearchBottom ? "bottom-fixed" : ""
          }`}
          modalDemoMode={this.modalDemoMode}
        />
      </div>
    );
  };

  modalDemoMode = () => {
    this.renderModal(
      <Information
        title={"Modo Demo"}
        text={"Este é o momento em que teria acesso a um novo Prestador.\n Indisponível na versão Demo."}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
        />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  onSwiper = (swiper) => {
    this.swiperInstance = swiper;
  }

  goToSection = (id, behavior = "smooth") => {
    const element = document.getElementById(`section-${id}`);
    if (id === 1) {
      this.goToTop();
    } else {
      this.setState({isNavigating: true})
      const container = document.getElementById("menu-key-container");
      container.classList.remove("snap-y-proximity");
      setTimeout(() => {        
        element.scrollIntoView({
          block: "start",
          inline: "start",
          behavior,
        });
      }, 100);
    }
  }

  goToTop = () => {
    const element = document.getElementById("menu-key-container");
    element.classList.remove("snap-y-proximity");
    setTimeout(() => {
      element.scrollTo({top: 0, behavior: "smooth"});
      //  this.setState({searchCompanySectionActive: false});
    }, 200);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="side-bar-menu-container-block p-0"
          style={{maxWidth: "100%"}}
          ref={(menuElement) => {
            this.menuElement = menuElement;
          }}
        >
          <div id="menu-key-container" className="menu-key-content-container pt-0">
            <section id="section-1" className="menu-section">
              {this.renderDefaultView()}
            </section>
            <section id="section-2" className="menu-section" onClick={() => this.goToSection(2)}>
              {this.renderNewCompanySection()}
            </section>
            {!this.state.categories?.length ? null : <div className="d-none d-md-block scroll-base-container" id="scroll-base-container"></div>}
          </div>
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }
}

export default Companies;
