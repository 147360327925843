// Code for initializing HotJar and UXCam

class Analytics {
    init = () => {
        this.initializeHotJar();
    }

    initializeHotJar = () => {
        (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: 5175057, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); 
            r.async = 1;
            r.id = 'hotjar-script';
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }

    unmount = () => {
        this.unmountHotJar();
    }

    unmountHotJar = () => {
        const hotjarScript = document.getElementById('hotjar-script');
        if (hotjarScript) {
            hotjarScript.parentNode.removeChild(hotjarScript);
        }
    }

}
export default Analytics;