export class GeoLocationService {

  /**
   * Get the geolocation coordinates.
   * @returns {Promise<string | undefined>} A promise that resolves to a string
   * representing the geolocation coordinates in the format "latitude,longitude",
   * or undefined if geolocation is not available or an error occurs.
   */
  static getGeolocation = () => {
    return new Promise((resolve) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          /**
           * Success callback for retrieving geolocation.
           * @param {GeolocationPosition} position - The geolocation position.
           */
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve(`${latitude},${longitude}`);
          },
          /**
           * Error callback for retrieving geolocation.
           * @param {GeolocationPositionError} error - The geolocation error.
           */
          (error) => {
            console.error('Error getting geolocation:', error);
            resolve(undefined); // Return undefined when an error occurs
          }
        );
      } else {
        console.error('Geolocation is not available in this browser.');
        resolve(undefined); // Return undefined when geolocation is not available
      }
    });
  }
  
}
