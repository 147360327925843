import React from 'react';
import { GENERAL } from '../../../assets/images/images';

const CustomOption = (props) => {
  const { data, innerRef, innerProps, getStyles, onRemove } = props;

  // Estilos personalizados do react-select
  const optionStyles = getStyles('option', props);

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        ...optionStyles,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>{data.label}</span>
      {data?.removable && <img
          src={GENERAL.delete}
          alt='remove'
          style={{ color: 'red', cursor: 'pointer', width: "16px", height: "16px" }}
          onClick={(e) => {
            e.stopPropagation(); // prevent the option be selected
            onRemove(data);
          }}
        />}
    </div>
  );
};

export default CustomOption;
